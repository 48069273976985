import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import DecodeCertificate from './DecodeCertificate';


const App = () => {
    return (
        <div>
            <Router>
                <nav className="navbar navbar-expand-lg navbar-dark bg-primary mb-5 py-1">
                    <div className="container">
                        <Link to="/" className="navbar-brand py-1 mr-auto" style={{ height: "50px" }}>
                            <img src="logo.svg" className="p-relative" height="75" width="75" alt="ChadGolden.PKI Digital Certificate Decoder" />
                            <span className="navbar-brand-name">Digital Certificate Decoder</span>
                        </Link>
                    </div>
                </nav>
                <div className="container main px-4">
                    <Switcher />
                </div>
            </Router>
            <footer className="footer p-3 bg-light border-top">
                <div className="container">
                    ChadGolden.PKI Digital Certificate Decoder &amp; Validator &mdash; &copy; { new Date().getFullYear() } Chad Golden.
                </div>
            </footer>
        </div>
    );
}

const Switcher = () => {
    return (
        <Switch>
            <Route exact path="/">
                <DecodeCertificate />
            </Route>
        </Switch>
    );
}

export default App;