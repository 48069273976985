import * as React from "react";
import { config } from './config';
import axios, { AxiosResponse } from 'axios';
import $, { param } from 'jquery';
import 'bootstrap/js/dist/toast';

type DecodeCertificateState = {
    submittedCert: DecodedCertificateResponse,
    pem: string,
    decoding: boolean
}

class DecodeCertificate extends React.Component<any, DecodeCertificateState> {
    state = {
        submittedCert: { certificate: null } as DecodedCertificateResponse,
        pem: "",
        decoding: false
    }

    componentDidMount() {
        this.initToast();
    }

    render() {
        if (this.state.submittedCert.certificate !== null) {
            return (
                <div className="mb-3">
                    <button className="btn btn-sm btn-link my-0" onClick={this.backToDecode}>&larr; Back to Decode</button>
                    <h2>Certificate Information</h2>
                    <div>
                        <DisplayDecodedCertificate certificate={this.state.submittedCert.certificate} />
                    </div>
                    <ValidateCertificate certificatePem={ this.state.pem } />
                </div>
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-lg-8">
                        <form onSubmit={this.onRequestClientCertFormSubmit}>
                            <button type="submit" className="btn btn-primary">Request Client Certificate & Decode</button>
                        </form>
                        <p className="font-weight-light my-4">
                            &mdash; OR &mdash;
                        </p>
                        <form onSubmit={ this.onFormSubmit }>
                            <div className="form-group">
                                <label htmlFor="CertPem" className="control-label">Input Certificate (PEM)</label>
                                <textarea id="CertPem" className="form-control" rows={12} placeholder={ this.placeholderCertPem }></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={ this.state.decoding }>Decode</button>
                            <div className="toast my-3" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
                                <div className="toast-header">
                                    <strong className="mr-auto text-danger">Error</strong>
                                    <small className="text-muted">just now</small>
                                    <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="toast-body">
                                        An error occurred trying to decode the certificate. Please ensure the correct format and try again.
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    private onFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const pem = (document?.getElementById("CertPem") as HTMLInputElement).value || this.exampleCert;
        const formData = new FormData();
        formData.append("pem", pem);
        this.setState({ decoding: true });
        axios.post(`${config.API_URL}/pki/decode`, formData)
            .then((response: AxiosResponse<DecodedCertificate>) => {
                this.setState({
                    submittedCert: {
                        certificate: response.data,
                    },
                    pem: pem
                });
            })
            .catch(() => {
                this.showToast();
            })
            .finally(() => {
                this.setState({ decoding: false });
            });
    }

    private onRequestClientCertFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        this.setState({ decoding: true });
        let pem = "";
        axios.post(`https://pki.chadgolden.com:8443/pki/request-client-certificate`)
            .then((response: AxiosResponse<DecodedCertificate>) => {
                this.setState({
                    submittedCert: {
                        certificate: response.data,
                    },
                    pem: pem
                });
            })
            .catch(() => {
                this.showToast();
            })
            .finally(() => {
                this.setState({ decoding: false });
            });
    }

    private backToDecode = () => {
        this.setState({
            submittedCert: {
                certificate: null
            },
            pem: ""
        });
    }

    private initToast = () => {
        const toast = $('.toast') as any;
        toast.toast();
    }

    private showToast = () => {
        const toast = $('.toast') as any;
        toast.toast('show');
    }

    private placeholderCertPem: string = `-----BEGIN CERTIFICATE-----
MIIFKTCCBBGgAwIBAgISA98u8YDue09xlH8BZrgzZGvuMA0GCSqGSIb3DQEBCwUA
MDIxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQswCQYDVQQD
EwJSMzAeFw0yMTAxMTAxNjEyMDNaFw0yMTA0MTAxNjEyMDNaMB0xGzAZBgNVBAMT
EnBraS5jaGFkZ29sZGVuLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoC
ggEBAOKkRN/4pYFmX+bvsFK8fmkpoCHa1ZE0HIIZpVQfHzXTdI4NrL91xw9YjVqA
929GYC0NJmt3Nj6/CW0iycQ4REqDKLQZRbKPJex0EHppsg5W7HpGIMNc2io8R...
-----END CERTIFICATE-----`;

    private exampleCert: string = `-----BEGIN CERTIFICATE-----
MIIFRzCCBC+gAwIBAgISBEZl6ME0EgOFq5vZzM8SHgcTMA0GCSqGSIb3DQEBCwUA
MDIxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQswCQYDVQQD
EwJSMzAeFw0yNDAzMzExOTI4MzRaFw0yNDA2MjkxOTI4MzNaMBkxFzAVBgNVBAMT
DmNoYWRnb2xkZW4uY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA
kcJhhXiASKnhYQ5/zPQCnVQ+A8HjhuinA7CiaIfqyNFlk22VkoCGQLuU2eeQimCy
MoykJAQaZSwo8cLWaAv4uxzEm0Fbi2Fc6iWcskl8RrNRNtbRqdYeH8xQzYxrLHHT
05VXXDzTAuNLJfcj5JYO9Esc3GryMV+bqwAmHnSDWFeS+IRjHNnxc1tD6j39lTYU
+BKPquhSLSrT5tA7SCniV7q3NJ4wK4SzeVd/w2g6NDZ3CD4eEBLT5e5ko7fCVxUE
0gZUYGH7uRiI0VxhKHM4SNRM0VEZMIUgRF+yyeoUZjT2qFHYZi/4skcpXGWSzgyM
WE5sdZCgEs6N7PdMGYGb6wIDAQABo4ICbjCCAmowDgYDVR0PAQH/BAQDAgWgMB0G
A1UdJQQWMBQGCCsGAQUFBwMBBggrBgEFBQcDAjAMBgNVHRMBAf8EAjAAMB0GA1Ud
DgQWBBR/rvmtPMWODsQwu6WkVBB4qB8o/TAfBgNVHSMEGDAWgBQULrMXt1hWy65Q
CUDmH6+dixTCxjBVBggrBgEFBQcBAQRJMEcwIQYIKwYBBQUHMAGGFWh0dHA6Ly9y
My5vLmxlbmNyLm9yZzAiBggrBgEFBQcwAoYWaHR0cDovL3IzLmkubGVuY3Iub3Jn
LzB2BgNVHREEbzBtgg5jaGFkZ29sZGVuLmNvbYIScGtpLmNoYWRnb2xkZW4uY29t
ghZyZWNpcGVzLmNoYWRnb2xkZW4uY29tght3aGF0czRkaW5uZXIuY2hhZGdvbGRl
bi5jb22CEnd3dy5jaGFkZ29sZGVuLmNvbTATBgNVHSAEDDAKMAgGBmeBDAECATCC
AQUGCisGAQQB1nkCBAIEgfYEgfMA8QB2AKLiv9Ye3i8vB6DWTm03p9xlQ7DGtS6i
2reK+Jpt9RfYAAABjpYzF2AAAAQDAEcwRQIhAKwblxe8EJjqZTCX0SJwhm8che22
DRGC7R3KJSuxrXMCAiBoHs0+9QoVmRMUlBSZOWhELcOlp3/J1obIv+KV3j38VQB3
AEiw42vapkc0D+VqAvqdMOscUgHLVt0sgdm7v6s52IRzAAABjpYzFzkAAAQDAEgw
RgIhAJ4AAPhazpltsgSIYZWGVYO9Rnv0wyriYIGVOz/mDzLCAiEAq1sLrm5gzkaJ
Rz4BKtDYgs51tnKzKkyrtFMy8kKcClYwDQYJKoZIhvcNAQELBQADggEBAHO2KWUf
IG0ReLwrJYgLcsxpEHCbib6gZBAj2f29B8pL6ViQj+hr4p7/r7eq0e1i9mEd10yV
2Ib7GTvcz4I1+V8EqXVHuwkocIeBTrLhqdpI3q8N/ALhX7R/dGF0qbuk5TgplkSh
AnFJl2KmahqNlrO1zTH5Qkkid7Yf79hHFlDcwIzLSFYhMTa40lKuo3Nx+0s47ER5
dcbeY1PeI8+M7rGl6K31ZnNu7oTi1o2F9SxMjsiNgPyhAVMCwaSlWcQ/llXR6pZp
M3PM3CCeP43lGCGJd60jkNmKXbFSCs4+yGaD55BuvwVg4CdvlWKO1pf+u49t/6dY
6wwTk58lp9/KpAU=
-----END CERTIFICATE-----`;
}

type DecodedCertificateResponse = {
    certificate: DecodedCertificate | null;
}

type DecodedCertificate = {
    subject: string;
    issuer: string;
    version: number;
    notBefore: string;
    notAfter: string;
    thumbprint: string;
    serialNumber: string;
    authorityInformationAccess: string;
    subjectInformationAccess: string;
    policies: string[];
    enhancedKeyUsages: string[];
    subjectAlternativeName: string;
    subjectKeyIdentifier: string;
    authorityKeyIdentifier: string;
    fullyParsed: string;
}

const DisplayDecodedCertificate = (response: DecodedCertificateResponse) => {
    return (
        <div>
            <CertificateFieldDisplay name={"Subject"} data={response.certificate?.subject} />
            <CertificateFieldDisplay name={"Issuer"} data={response.certificate?.issuer} />
            <CertificateFieldDisplay name={"Version"} data={response.certificate?.version} />
            <CertificateFieldDisplay name={"Not Before"} data={response.certificate?.notBefore} />
            <CertificateFieldDisplay name={"Not After"} data={response.certificate?.notAfter} />
            <CertificateFieldDisplay name={"Thumbprint"} data={response.certificate?.thumbprint} />
            <CertificateFieldDisplay name={"Serial Number"} data={response.certificate?.serialNumber} />
            <CertificateFieldDisplay name={"Subject Alternative Name"} data={response.certificate?.subjectAlternativeName} preFormatted={true} />
            <CertificateFieldDisplay name={"Authority Information Access (AIA)"} data={response.certificate?.authorityInformationAccess} preFormatted={true} />
            <CertificateFieldDisplay name={"Subject Information Access (SIA)"} data={response.certificate?.subjectInformationAccess} preFormatted={true} />
            <CertificateFieldDisplay name={"Authority Key Identifier"} data={response.certificate?.authorityKeyIdentifier} />
            <CertificateFieldDisplay name={"Subject Key Identifier"} data={response.certificate?.subjectKeyIdentifier} />
            <CertificateFieldDisplayMany name={"Certificate Policies"} data={response.certificate?.policies} />
            <CertificateFieldDisplayMany name={"Enhanced Key Usages"} data={response.certificate?.enhancedKeyUsages} />
            <FullyParsedDetails fullyParsed={response.certificate?.fullyParsed || ""} thumbprint={response.certificate?.thumbprint || ""} />
        </div>
    );
}

type FieldDisplayData = {
    name: string;
    data?: string | number | Array<string>;
    preFormatted?: boolean;
}

const CertificateFieldDisplay = (fieldDisplayData: FieldDisplayData) => {
    if (fieldDisplayData.data === null || fieldDisplayData.data === "") {
        return (
            <span></span>
        );
    }

    const preFormattedCss = fieldDisplayData.preFormatted ? "whitespace-pre overflow-auto" : "";
    return (
        <div className="mb-3">
            <h4 className="h6 font-weight-bold mb-0">{fieldDisplayData.name}</h4>
            <div className={ preFormattedCss }>{ fieldDisplayData.data }</div>
        </div>
    );
}

const CertificateFieldDisplayMany = (fieldDisplayData: FieldDisplayData) => {
    const data: string[] = fieldDisplayData.data as string[];
    if (data.length === 0) {
        return (
            <span></span>
        );
    }

    const dataRows = data.map(
        (row: string, index: number) => {
            return (
                <div>
                    {row}
                </div>
            );
        }
    );

    return (
        <div className="mb-3">
            <h4 className="h6 font-weight-bold mb-0">{fieldDisplayData.name}</h4>
            <div>
                {dataRows}
            </div>
        </div>
    );
}

type ValidateCertificateResponse = {
    isValid: boolean;
    status: string[];
    chainCertificates: DecodedCertificate[];
}

type ValidateCertificateProps = {
    certificatePem: string
}

class ValidateCertificate extends React.Component<ValidateCertificateProps> {
    state = {
        validated: false,
        validatedCertificate: {} as ValidateCertificateResponse,
        validating: false
    };

    componentDidMount() {
        this.initToast();
    }

    render() {
        if (!this.state.validated) {
            return (
                <div>
                    <button type="button" className="btn btn-primary my-3" onClick={this.validate} disabled={this.state.validating}>Validate &#9989;</button>
                    <div className="toast my-3" role="alert" aria-live="assertive" aria-atomic="true" data-autohide="false">
                        <div className="toast-header">
                            <strong className="mr-auto text-danger">Error</strong>
                            <small className="text-muted">just now</small>
                            <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="toast-body">
                            An error occurred trying to validate the certificate. Please try again later.
                        </div>
                    </div>
                </div>
            );
        }

        const chain = this.state.validatedCertificate.chainCertificates.map(
            (cert, index) => {
                const isLeaf: boolean = index === this.state.validatedCertificate.chainCertificates.length - 1;

                return (
                    <ChainCertificate certificate={cert} depth={index} isLeaf={isLeaf} />
                );
            }
        );

        return (
            <div className="my-4">
                <h2>Validation Summary</h2>
                <div>
                    <IsValidAlert isValid={this.state.validatedCertificate.isValid} />
                    <CertificateStatus status={ this.state.validatedCertificate.status } />
                    <h4 className="h5">Chain of Trust</h4> {chain}
                </div>
            </div>
        );
    }

    private validate = () => {
        const formData = new FormData();
        formData.append("pem", this.props.certificatePem);
        this.setState({ validating: true });
        axios.post(`${config.API_URL}/pki/validate`, formData)
            .then((response: AxiosResponse<ValidateCertificateResponse>) => {
                this.setState({
                    validated: true,
                    validatedCertificate: response.data
                });
            })
            .catch(() => {
                this.showToast();
            })
            .finally(() => {
                this.setState({ validating: false });
            });
    }

    private initToast = () => {
        const toast = $('.toast') as any;
        toast.toast();
    }

    private showToast = () => {
        const toast = $('.toast') as any;
        toast.toast('show');
    }
}

const IsValidAlert = (params: { isValid: boolean }) => {
    const validText: string = params.isValid ? "valid" : "invalid";
    const validEmojiCharCode: number = 9989;
    const invalidEmojiCharCode: number = 10060;
    const charCode: number = params.isValid ? validEmojiCharCode : invalidEmojiCharCode;
    return (
        <div className="alert alert-light border my-2" role="alert">
            <span className="mr-2">{ String.fromCharCode(charCode) }</span>
            The certificate is { validText }.
        </div>
    );
}

const ChainCertificate = (params: { certificate: DecodedCertificate, depth: number, isLeaf: boolean }) => {
    const cert: DecodedCertificate = params.certificate as DecodedCertificate;
    const marginLeft = { marginLeft: params.depth + "rem" };

    if (params.isLeaf) {
        return (
            <div className="p-2" style={marginLeft}>{cert.subject} <span title="Leaf certificate">&#127811;</span></div>  
        );
    }

    return (
        <div className="card mb-1" style={ marginLeft }>
            <div className="card-header p-0" id={ "heading" + cert.thumbprint }>
                <h2 className="mb-0">
                    <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={"#collapse" + cert.thumbprint} aria-expanded="false" aria-controls={"collapse" + cert.thumbprint}>
                        {cert.subject}
                    </button>
                </h2>
            </div>
            <div id={"collapse" + cert.thumbprint} className="collapse" aria-labelledby={"heading" + cert.thumbprint}>
                <div className="card-body pb-0">
                    <DisplayDecodedCertificate certificate={cert} />
                </div>
            </div>
        </div>
    );
}

const CertificateStatus = (params: { status: string[] }) => {
    if (params.status.length === 0) {
        return (
            <div></div>
        );
    }

    const rows = params.status.map(
        (row, index) => {
            return (
                <div>{row}</div>
            );
        }
    );

    return (
        <div className="mb-2">
            <h4 className="h5 mb-0">Status</h4>
            { rows }
        </div>
    );
}

const FullyParsedDetails = (params: { fullyParsed: string, thumbprint: string }) => {
    const collapseId: string = `viewParsed${ params.thumbprint }`;
    return (
        <div className="mb-2">
            <h4 className="h6 font-weight-bold mb-0">Fully Parsed Certificate</h4>
            <a href={"#" + collapseId} data-toggle="collapse" aria-expanded="false" aria-controls={collapseId}>View</a>
            <div id={ collapseId } className="collapse mt-2">
                <div className="card card-body border shadow-sm whitespace-pre overflow-auto" style={{ fontSize: "0.8rem", lineHeight: "1.1" }}>
                    <code className="text-dark">{params.fullyParsed}</code>
                </div>
            </div>
        </div>
    );
}

export default DecodeCertificate;